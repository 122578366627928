import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from '../../api';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const initialRows = [
  {
    id: 'initiId',
    name: 'initName',
    tel: 'initTel',
    regDate: 'init-reg-date',
  },
];

export const PartnerList = () => {
  const history = useHistory();
  const [rows, updateRows] = useState(initialRows);
  const useRowStyles = makeStyles({
    container: {
      marginBottom: 40,
    },
    root: {
      cursor: 'pointer',
      '&:hover': {
        background: '#efefef6b',
      },
    },
  });

  const classes = useRowStyles();

  useEffect(() => {
    API.Partenrs.list().then(
      (res) => {
        console.log('res', res);
        if (res && res.data) {
          updateRows(res.data);
        }
      },
      (err) => {
        console.log('err', err);
      },
    );
  }, []);

  const onClickRow = (row) => {
    console.log('onClickRow', row);
    history.push('/partner/detail/' + row.uid);
  };

  return (
    <article className='partner-page list'>
      <TableContainer component={Paper} className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>이름</TableCell>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>전화번호</TableCell>
              <TableCell align='center'>등록일자</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow className={classes.root} key={row.name} onClick={() => onClickRow(row)}>
                <TableCell component='th' scope='row' align='center'>
                  {row.name}
                </TableCell>
                <TableCell align='center'>{row.id}</TableCell>
                <TableCell align='center'>{row.tel}</TableCell>
                <TableCell align='center'>{row.regDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </article>
  );
};

export default PartnerList;
