import React, { useState } from 'react';
import Utils from '../../utils';
import { Navigation } from '../../components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';

const MainLayout = (props) => {
  let { Component, headerText, ...rest } = props;
  let history = useHistory();

  let isLoggenIn = localStorage.getItem('healthport-admin-login');
  if (!isLoggenIn) {
    history.push('/login');
  }

  const logout = () => {
    localStorage.setItem('healthport-admin-login', '');
    history.push('/login');
  };

  const [openMenu, updateOpenMenu] = useState(false);

  const toggleDrawer = () => {
    updateOpenMenu(!openMenu);
  };

  const useStyles = makeStyles(() => ({
    drawer: {
      width: '240px',
    },
  }));
  const classes = useStyles();
  console.log('main props', props);
  console.log('main history', useRouteMatch(history.location.pathname));

  return (
    <div className='main-layout'>
      <article className='navigation-wrapper'>
        <Navigation />
      </article>
      <Drawer
        anchor={'left'}
        open={openMenu}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawer,
        }}>
        {<Navigation />}
      </Drawer>

      <article className='content-wrapper'>
        <section className='header'>
          <section>
            <IconButton className='toggle-nav' aria-label='open drawer' onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
            <div className='router-text'>{headerText}</div>
          </section>

          <section>
            <IconButton className='logout' aria-label='open drawer' onClick={logout}>
              <ExitToAppIcon />
            </IconButton>
          </section>
        </section>

        <section className='scroll__box'>
          <article className='content'>
            <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
          </article>
        </section>
      </article>
    </div>
  );
};

export default MainLayout;
