import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../api';
import { AlertContext } from '../../context';
import { Inputs } from '../../components';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

export const PartnerDetail = () => {
  let { partnerId } = useParams();
  useEffect(() => {
    console.log('history', partnerId);
    API.Partenrs.detail(partnerId).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err);
      },
    );
  }, []);

  return <article className='partner-page detail'>detail</article>;
};
