import axios from 'axios';
import { BASE_URL } from './config';

const AxiosInstance = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

// 요청 인터셉터 추가
AxiosInstance.interceptors.request.use(
  (config) => {
    // IE config
    if (config.method === 'get') {
      const customHeaders = {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: 0,
      };
      config.headers = customHeaders;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터 추가
AxiosInstance.interceptors.response.use(
  (response) => {
    console.log('response', response);

    return response;
  },
  (error) => {
    if (error) {
      console.log('net work error', error);
    }

    return Promise.reject(error);
  },
);

export default AxiosInstance;
