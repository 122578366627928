import React, { useState, useContext, useEffect } from 'react';
import API from '../../api';
import Utils from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const initialPageInfo = {
  page: 1,
  count: 10,
};

const initialRows = [
  {
    cash: 10000,
    index: 21,
    license: '00619',
    name: '테스트약국0619',
    pharmProgram: 'PIT3000',
    serviceType: 'L',
    storeId: '20200600619',
    tel: '01024700619',
    userName: '테스트대표약사0619',
    userPhone: '01024700619',
  },
];

export const PharmList = () => {
  let history = useHistory();
  const [pageInfo, updatePageInfo] = useState(initialPageInfo);
  const [rows, updateRows] = useState(initialRows);
  const [totalCount, updateTotalCount] = useState(1);
  const totalPage = Math.ceil(totalCount / pageInfo.count);
  const useStyles = makeStyles((theme) => ({
    tableRow: {
      cursor: 'pointer',
      '&:hover': {
        background: '#efefef6b',
      },
    },
  }));
  const classes = useStyles();

  const requestNewRows = () => {
    let params = {
      page: pageInfo.page,
      count: pageInfo.count,
      pharmName: document.getElementById('search-input').value,
    };

    API.Pharms.getList(params).then(
      (res) => {
        console.log('res', res);
        if (res && res.data) {
          updateTotalCount(res.data.count);
          updateRows(res.data.resultList);
        }
      },
      (err) => {
        console.log('err', err);
      },
    );
  };

  useEffect(() => {
    requestNewRows();
  }, [pageInfo]);

  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      updatePageInfo({
        ...pageInfo,
        page: 1,
      });
    }
  };

  const onClickSearch = () => {
    updatePageInfo({
      ...pageInfo,
      page: 1,
    });
  };

  const onChangeRowCount = (e) => {
    let newCount = e.target.value;
    updatePageInfo({
      ...pageInfo,
      count: newCount,
      page: 1,
    });
  };

  const onChangePage = (e, value) => {
    updatePageInfo({
      ...pageInfo,
      page: value,
    });
  };

  const onClickRow = (row) => {
    history.push('/pharm/detail/' + row.storeId);
  };

  return (
    <article className='pharm-page list'>
      <section className='filter__container'>
        <section className='search__box'>
          <TextField id='search-input' label='약국명 입력' onKeyDown={onKeyPress} />
          <Button variant='contained' color='primary' onClick={onClickSearch}>
            검색
          </Button>
        </section>

        <section className='select__box'>
          <FormControl>
            <InputLabel>줄 수</InputLabel>
            <Select value={pageInfo.count} onChange={onChangeRowCount}>
              <MenuItem value={10}>10개</MenuItem>
              <MenuItem value={30}>30개</MenuItem>
              <MenuItem value={50}>50개</MenuItem>
            </Select>
          </FormControl>
        </section>
      </section>
      <section className='table__container'>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>번호</TableCell>
                <TableCell align='center'>이름</TableCell>
                <TableCell align='center'>대표약사</TableCell>
                <TableCell align='center'>면허번호</TableCell>
                <TableCell align='center'>전화번호</TableCell>
                <TableCell align='center'>핸드폰번호</TableCell>
                <TableCell align='center'>서비스타입</TableCell>
                <TableCell align='center'>약국 프로그램</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={'table-row-' + row.name + '-' + row.storeId}
                  className={classes.tableRow}
                  onClick={() => onClickRow(row)}>
                  <TableCell align='center' component='th' scope='row'>
                    {row.index}
                  </TableCell>
                  <TableCell align='center'>{row.name}</TableCell>
                  <TableCell align='center'>{row.userName}</TableCell>
                  <TableCell align='center'>{row.license}</TableCell>
                  <TableCell align='center'>{row.tel}</TableCell>
                  <TableCell align='center'>{row.userPhone}</TableCell>
                  <TableCell align='center'>
                    {Utils.StaticVar.ServiceTypeToString(row.serviceType)}
                  </TableCell>
                  <TableCell align='center'>{row.pharmProgram}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
      <section className='pagination__container'>
        <Pagination
          page={pageInfo.page}
          onChange={onChangePage}
          count={totalPage}
          color='primary'
          showFirstButton
          showLastButton
        />
      </section>
    </article>
  );
};
