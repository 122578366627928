const pharmProgram = [
  {
    value: 'PIT3000',
    label: 'PIT3000',
  },
  {
    value: 'UPharm',
    label: 'UPharm',
  },
  {
    value: 'NSPharm',
    label: 'NSPharm',
  },
  {
    value: 'CNPharm',
    label: 'CNPharm',
  },
  {
    value: 'BizPharm',
    label: 'BizPharm',
  },
];

const serviceType = [
  {
    value: 'L',
    label: 'Lite',
  },
  {
    value: 'P',
    label: 'Plus',
  },
];

const SelectOption = {
  pharmProgram: pharmProgram,
  serviceType: serviceType,
};

export default SelectOption;
