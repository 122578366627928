import React, { useState } from 'react';
import { LoginLayout } from '../../layout';
import { Button, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Login = () => {
  let history = useHistory();

  const initialUserInputs = {
    id: '',
    pw: '',
  };
  const [userInputs, updateUserInputs] = useState(initialUserInputs);

  const onChange = (e) => {
    e.persist();
    updateUserInputs({
      ...userInputs,
      [e.target.name]: e.target.value,
    });
  };

  const requestLogin = () => {
    if (
      userInputs.id &&
      userInputs.pw &&
      userInputs.id === 'admin' &&
      userInputs.pw === 'qwer1234'
    ) {
      localStorage.setItem('healthport-admin-login', '1');
      history.push('/');
    } else {
      alert('로그인 정보를 확인하세요.');
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      requestLogin();
    }
  };

  return (
    <article className='login-page'>
      <TextField id='user-id' label='아이디' name='id' value={userInputs.id} onChange={onChange} />
      <TextField
        type='password'
        id='user-pw'
        label='비밀번호'
        name='pw'
        value={userInputs.pw}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      <Button variant='contained' color='primary' onClick={requestLogin}>
        로그인
      </Button>
    </article>
  );
};

export default Login;
