import React, { useState, useEffect } from 'react';
import API from '../../api';
import _ from 'lodash';
import Utils from '../../utils';
import { Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles({
  table: {},
});

const initialRows = [
  {
    address: '0619testadd',
    addressDetail: '0619testadddetial',
    idx: 8,
    joinStatus: 'R',
    lat: 0,
    licenseNo: '00030',
    lng: 0,
    masterName: '0619test대표약사',
    originCode: '0619test요양기관코드',
    password: '6c057ac7f5db075f1ef17e1ff76fabed',
    pharmName: '0619test',
    pharmProgram: null,
    phone: '06190102470',
    regDate: '2020-06-19T10:04:01',
    reportingDate: '2020-06-19T10:04:01',
    serviceType: 'L',
    sido: null,
    sidogu: null,
    sidoguCode: null,
    taxNo: '0619test사업자번호',
    tel: '06190102470',
  },
];

const initialPartstList = [
  {
    id: '00000',
    name: '테스트파트너',
    password: 'e10adc3949ba59abbe56e057f20f883e',
    pharmPartnerInfo: [],
    regDate: '2020-05-12T14:09:05',
    tel: '010-3434-1351',
    uid: 1,
  },
];

export const PharmWait = () => {
  const classes = useStyles();
  const [rows, updateRows] = useState(initialRows);
  const [selectedPartnerName, updateselectedPartnerName] = useState(initialPartstList[0].name);
  const [selectedIdx, updateSelectedIdx] = useState(0);
  const [partnerList, updatePartnerList] = useState(initialPartstList);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    API.Pharms.getWaitList().then(
      (res) => {
        console.log('res', res);
        if (res && res.data) {
          updateRows(res.data);
        }
      },
      (err) => {
        console.log('err', err);
      },
    );

    API.Partenrs.list().then(
      (res) => {
        console.log(' list res', res);
        if (res && res.data) {
          updatePartnerList(res.data);
        }
      },
      (err) => {
        console.log('err', err);
      },
    );
  }, []);

  const onClickCellAloocate = (row) => {
    console.log('row', row);
    updateSelectedIdx(row.idx);
    handleOpen();
  };

  const handleChange = (e) => {
    updateselectedPartnerName(e.target.value);
  };

  const onClickRegister = () => {
    console.log('onClickRegister', selectedIdx);
    console.log('onClickRegister', selectedPartnerName);
    handleClose();
    let body = {
      subscriptionIdx: selectedIdx,
      partnerUid: _.find(partnerList, (p) => {
        return p.name === selectedPartnerName;
      }).uid,
    };
    API.Pharms.waitedPharmToPartner(body).then(
      (res) => {
        if (res && res.status === 200) {
          console.log('res', res);
          API.Pharms.getWaitList().then(
            (listRes) => {
              console.log('listRes', listRes);
              if (listRes && listRes.data) {
                updateRows(listRes.data);
              }
            },
            (err) => {
              console.log('err', err);
            },
          );
        }
      },
      (err) => {
        console.log('err', err);
      },
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <article className='pharm-page wait'>
      <section className='table__container'>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align='center'>약국명</TableCell>
                <TableCell align='center'>약사명</TableCell>
                <TableCell align='center'>면허번호</TableCell>
                <TableCell align='center'>서비스 타입</TableCell>
                <TableCell align='center'>등록일</TableCell>
                <TableCell align='center'>전화번호</TableCell>
                <TableCell align='center'>비고</TableCell>
              </TableRow>
            </TableHead>
            {rows.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell align='center' colSpan={7}>
                    대기 목록이 없습니다.
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align='center' component='th' scope='row'>
                      {row.pharmName}
                    </TableCell>
                    <TableCell align='center'>{row.masterName}</TableCell>
                    <TableCell align='center'>{row.licenseNo}</TableCell>
                    <TableCell align='center'>
                      {Utils.StaticVar.ServiceTypeToString(row.serviceType)}
                    </TableCell>
                    <TableCell align='center'>{row.regDate}</TableCell>
                    <TableCell align='center'>{row.tel}</TableCell>
                    <TableCell align='center'>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => onClickCellAloocate(row)}>
                        파트너 할당
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'>
        <Paper
          elevation={0}
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '360px',
            height: 'auto',
            background: '#fff',
            padding: '20px',
          }}>
          <FormControl component='fieldset'>
            <FormLabel component='legend' style={{ marginBottom: '20px' }}>
              파트너 선택
            </FormLabel>
            <RadioGroup name='partner' value={selectedPartnerName} onChange={handleChange}>
              {partnerList.map((partner) => {
                return (
                  <FormControlLabel
                    value={partner.name}
                    control={<Radio color='primary' />}
                    label={partner.name}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <section style={{ marginTop: '20px' }}>
            <Button variant='contained' color='primary' onClick={onClickRegister}>
              등록
            </Button>
          </section>
        </Paper>
      </Modal>
    </article>
  );
};
