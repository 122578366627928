import React, { useState, useContext, useEffect } from 'react';
import API from '../../api';
import { AlertContext } from '../../context';
import { useHistory, useParams } from 'react-router-dom';
import { Inputs } from '../../components';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Utils from '../../utils';
import _ from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const initialInputs = [
  {
    id: 'pharmName',
    type: 'text',
    readOnly: false,
    value: '',
    label: '약국명',
    regExp: /^.{1,20}$/,
    validationText: '1자 이상 20자 이내',
  },
  {
    id: 'serviceType',
    type: 'select',
    readOnly: false,
    value: '',
    label: '서비스 유형',
    options: Utils.SelectOption.serviceType,
  },
  {
    id: 'license',
    type: 'text',
    readOnly: false,
    value: '',
    label: '면허번호',
    regExp: /^\d{5}$/,
    validationText: '5자 입력',
  },
  {
    id: 'tel',
    type: 'text',
    readOnly: false,
    value: '',
    label: '전화번호(-없이 입력)',
    regExp: /^[0-9]{9,}$/,
    validationText: '전화번호는 9자 이상 숫자로 입력해주세요.',
  },
  {
    id: 'taxNo',
    type: 'text',
    readOnly: false,
    value: '',
    label: '사업자번호',
    regExp: /^.{1,20}$/,
    validationText: '1자 이상 20자 이내',
  },
  {
    id: 'pharmProgram',
    type: 'select',
    readOnly: false,
    value: '',
    label: '약국 프로그램',
    options: Utils.SelectOption.pharmProgram,
  },
  {
    id: 'cash',
    type: 'text',
    readOnly: true,
    value: '',
    label: '잔액',
  },
  {
    id: 'regDate',
    type: 'text',
    readOnly: true,
    value: '',
    label: '등록일',
  },
  {
    id: 'address',
    type: 'text',
    readOnly: true,
    value: '',
    label: '주소',
  },
  {
    id: 'addressDetail',
    type: 'text',
    readOnly: true,
    value: '',
    label: '상세 주소',
  },
];

function createData(regDate, type, content) {
  return { regDate, type, content };
}

const rows = [
  createData(
    '2020-08-13T13:05:08',
    'error',
    '내용입니다. asfkl;ds alkfjasdl; jafsdlk; flk;adsj fkl;asd fl;kajsdf lkjasdf l;kasdjfl;asd;fl',
  ),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
  createData('2020-08-13T13:05:08', 'error', '내용입니다.'),
];

export const PharmDetail = () => {
  let history = useHistory();
  let { storeId } = useParams();
  const { requestAlert } = useContext(AlertContext);
  const [inputData, updateInputData] = useState(initialInputs);
  const [tableLoading, updateTableLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    button_root: {
      minWidth: 100,
      marginRight: 20,
      '&:last-child': {
        marginRight: 0,
      },
    },
    radio_group: {
      flexDirection: 'row',
    },
  }));
  const classes = useStyles();

  const onInputsChange = (newInputData) => {
    console.log('onInputsChange', newInputData);
    updateInputData(newInputData);
  };

  const requestModify = _.debounce((param) => {
    console.log('requestModify - inputData', inputData);

    let alertData = {
      open: true,
      type: 'error',
      message: 'test message',
    };
    requestAlert(alertData);
  }, 500);

  useEffect(() => {
    API.Pharms.getDetail(storeId).then(
      (res) => {
        console.log('getDetail res', res);
        if (res && res.data) {
          let newInputData = _.clone(inputData);

          for (let i in newInputData) {
            console.log('inputData', inputData[i].id);
            let targetValue = res.data[newInputData[i].id];
            console.log('targetValue', targetValue);
            newInputData[i].value = targetValue || '';
          }
          console.log('newInputData', newInputData);
          updateInputData(newInputData);
        }
      },
      (err) => {
        console.log('err', err);
      },
    );

    var scrollContainer = document.getElementById('log-table-container');
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, []);

  const onScrollLogTable = (e) => {
    e.persist();
    console.log('onScrollLogTable', e.target.scrollTop);
    if (e.target.scrollTop === 0) {
      requestData();
    }
  };

  const requestData = _.debounce(() => {
    console.log('call requestData');
    updateTableLoading(true);

    setTimeout(() => {
      updateTableLoading(false);
    }, 3000);
  }, 500);

  const onChangeLog = (e) => {
    console.log('onChangeLog', e);
  };

  return (
    <article className='pharm-page detail'>
      <Paper elevation={3} className='detail__item'>
        <section className='header__box'>
          <Typography variant='h6' component='h6'>
            기본 정보
          </Typography>
        </section>

        <section className='form__box'>
          <ul className='form-list'>
            <Inputs data={inputData} onChange={onInputsChange} />
          </ul>
        </section>
        <section className='action__box'>
          <Button className={classes.button_root} variant='contained' color='secondary'>
            취소
          </Button>
          <Button
            className={classes.button_root}
            variant='contained'
            color='primary'
            onClick={requestModify}>
            수정
          </Button>
        </section>
      </Paper>
      <Paper elevation={3} className='detail__item'>
        <section className='header__box'>
          <Typography variant='h6' component='h6'>
            로그 정보
          </Typography>
          <RadioGroup
            className={classes.radio_group}
            name='log_select'
            value={'agent'}
            onChange={onChangeLog}>
            <FormControlLabel value='agent' control={<Radio color='primary' />} label='agent' />
            <FormControlLabel value='tablet' control={<Radio />} label='tablet' />
          </RadioGroup>
        </section>
        <TableContainer
          className={classes.table_container}
          id='log-table-container'
          onScroll={onScrollLogTable}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead className={classes.table_header}>
              <TableRow>
                <TableCell align='center'>시간</TableCell>
                <TableCell align='center'>타입</TableCell>
                <TableCell align='center'>내용</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableLoading && (
                <TableRow>
                  <TableCell align='center' colSpan={3}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <TableRow key={'table-log-row-' + index} className={classes.tableRow}>
                  <TableCell align='center' component='th' scope='row'>
                    {row.regDate}
                  </TableCell>
                  <TableCell align='center'>{row.type}</TableCell>
                  <TableCell align='center'>{row.content}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </article>
  );
};
