import { createContext } from 'react';

const alert = {
  //error,confirm
  open: true,
  type: 'init type',
  message: 'init message',
};

export const AlertContext = createContext({
  alert: alert,
  requestAlert: () => {},
});
