const ServiceTypeToString = (value) => {
  switch (value) {
    case 'L':
      return 'Lite';
    case 'P':
      return 'Plus';
    default:
      return '';
  }
};

const StaticVar = {
  ServiceTypeToString: ServiceTypeToString,
};

export default StaticVar;
