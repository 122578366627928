import React, { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const Alert = (props) => {
  console.log('props', props);

  const { open, type, message } = props.data;
  const handleClose = (event, reason) => {
    console.log('reason', reason);
    if (reason === 'clickaway') {
      return;
    }
    props.updateAlert({ open: false, type: '', message: '' });
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}>
      <MuiAlert onClose={handleClose} severity={type}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
