import { createContext } from 'react';

const loding = {
  //error,confirm
  open: true,
};

export const LoadingContext = createContext({
  loding: loding,
  requestLoading: () => {},
});
