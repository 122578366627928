import React, { useState, useContext } from 'react';
import API from '../../api';
import { AlertContext } from '../../context';
import { Inputs } from '../../components';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

const initialInputs = [
  {
    id: 'name',
    type: 'text',
    readOnly: false,
    value: '',
    label: '파트너 이름',
    regExp: /^.{1,20}$/,
    validationText: '1자 이상 20자 이내',
  },
  {
    id: 'id',
    type: 'text',
    readOnly: false,
    value: '',
    label: '아이디',
    regExp: /^.{4,20}$/,
    validationText: '4자 이상 20자 이내',
  },
  {
    id: 'password',
    type: 'password',
    readOnly: false,
    value: '',
    label: '비밀번호',
    regExp: /^.{4,20}$/,
    validationText: '4자 이상 20자 이내',
  },
  {
    id: 'password_check',
    type: 'password',
    readOnly: false,
    value: '',
    label: '비밀번호 확인',
  },
  {
    id: 'tel',
    type: 'text',
    readOnly: false,
    value: '',
    label: '전화번호(-없이 입력)',
    regExp: /^[0-9]{9,}$/,
    validationText: '전화번호는 9자 이상 숫자로 입력해주세요.',
  },
];

export const PartnerRegister = () => {
  const [inputData, updateInputData] = useState(initialInputs);
  const { requestAlert } = useContext(AlertContext);

  const onInputsChange = (newInputData) => {
    console.log('onInputsChange', newInputData);
    updateInputData(newInputData);
  };

  const requestRegister = () => {
    console.log('inputData', inputData);

    let emptyFlag = false;
    for (let i in inputData) {
      if (!inputData[i].value) {
        emptyFlag = true;
        break;
      }
      if (inputData[i].error) {
        emptyFlag = true;
        break;
      }
    }
    if (emptyFlag) {
      requestAlert({
        open: true,
        type: 'error',
        message: '입력사항을 확인해주세요.',
      });
      return;
    }

    let body = {
      name: _.find(inputData, (input) => {
        return input.id === 'name';
      }).value,
      id: _.find(inputData, (input) => {
        return input.id === 'id';
      }).value,
      password: _.find(inputData, (input) => {
        return input.id === 'password';
      }).value,
      tel: _.find(inputData, (input) => {
        return input.id === 'tel';
      }).value,
    };

    console.log('body', body);
    API.Partenrs.register(body).then(
      (res) => {
        console.log('res', res);
        if (res.status === 200) {
          let clearInputData = _.clone(inputData);
          for (let i in clearInputData) {
            clearInputData[i].value = '';
          }
          console.log('clearInputData', clearInputData);
          updateInputData(clearInputData);

          requestAlert({
            open: true,
            type: 'success',
            message: '성공적으로 등록되었습니다.',
          });
        } else {
          requestAlert({
            open: true,
            type: 'error',
            message: '에러가 발생했습니다. 관리자에게 문의해주세요',
          });
          return;
        }
      },
      (err) => {
        console.log('err', err);
        requestAlert({
          open: true,
          type: 'error',
          message: '에러가 발생했습니다. 관리자에게 문의해주세요',
        });
        return;
      },
    );
  };

  return (
    <article className='partner-page register'>
      <section className='form__box'>
        <ul className='form-list'>
          <Inputs data={inputData} onChange={onInputsChange} />
        </ul>
      </section>
      <section className='button__box'>
        <Button variant='contained' color='primary' fullWidth onClick={requestRegister}>
          등록
        </Button>
      </section>
    </article>
  );
};
