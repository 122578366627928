import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router-dom';

const LoginLayout = (props) => {
  let { Component, ...rest } = props;
  let history = useHistory();

  return (
    <article className='login-layout'>
      <section className='header'>
        <div className='logo'>
          <Link to='/'>
            <b>HealthPort Admin</b>
          </Link>
        </div>
      </section>
      <section className='content'>
        <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
      </section>
    </article>
  );
};

export default LoginLayout;
