import AxiosInstance from '../axiosInstance';
import {
  ObjectToParams,
  ObjectToQueryString,
  ArrayToStringComma,
  statusStringToValue,
  arrayTOStringCommaByKey,
  dateFormatToStringType,
} from '../helper';

const list = () => {
  return AxiosInstance.get('partners');
};

const register = (obj) => {
  return AxiosInstance.post('partners', obj);
};

const detail = (partenrId) => {
  return AxiosInstance.get(`partners/${partenrId}`);
};

const Partenr = {
  list: list,
  register: register,
  detail: detail,
};
export default Partenr;
