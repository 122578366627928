import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import API from '../../api';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const initialPageInfo = {
  page: 1,
  count: 10,
  search: '',
};

const initialRows = [
  {
    index: 1,
    storeId: '20200799997',
    name: '헬스포트약국 내부테스트3',
    license: '99997',
    agentVersion: '1.0.1.79',
    networkAgentVersion: '1.1.1.1',
    pharmProgram: 'PIT3000',
    requestIp: '61.98.77.206',
    agentIsOn: false,
    agentModuleIsConn: true,
    networkAgentAlive: '2020-07-28T14:23:04',
    tabletList: [
      {
        tabletId: 'HA10FHQD',
        deviceId: '84:B8:B8:8D:14:09',
        ipAddr: '90.241.74.79',
        appVersion: '1.0.10',
        buildVersion: '13',
        codePushVersion: 'v20',
        isOn: true,
        isOnDate: '2020-07-22T17:58:31',
        isConnected: false,
        isConnectedDate: '2020-07-28T14:24:09',
      },
    ],
  },
];
export const PharmMonitor = () => {
  const [pageInfo, updatePageInfo] = useState(initialPageInfo);
  const [rows, updateRows] = useState(initialRows);
  const [totalCount, updateTotalCount] = useState(1);
  const totalPage = Math.ceil(totalCount / pageInfo.count);

  const onChangeRowCount = (e) => {
    let newCount = e.target.value;
    updatePageInfo({
      ...pageInfo,
      count: newCount,
      page: 1,
    });
  };

  const onChangePage = (e, value) => {
    console.log('onChangePage', value);
    updatePageInfo({
      ...pageInfo,
      page: value,
    });
  };

  useEffect(() => {
    console.log('useEffect pageInfo', pageInfo);
    requestNewRows();
  }, [pageInfo]);

  const onClickSearch = () => {
    updatePageInfo({
      ...pageInfo,
      page: 1,
    });
  };

  const requestNewRows = () => {
    let params = {
      page: pageInfo.page,
      count: pageInfo.count,
      pharmName: document.getElementById('search-input').value,
    };

    API.Pharms.getMonitoring(params).then(
      (res) => {
        console.log('res', res);
        if (res && res.data) {
          updateTotalCount(res.data.count);
          updateRows(res.data.resultList);
        }
      },
      (err) => {
        console.log('err', err);
      },
    );
  };

  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      updatePageInfo({
        ...pageInfo,
        page: 1,
      });
    }
  };

  return (
    <article className='pharm-page monitor'>
      <section className='filter__container'>
        <section className='search__box'>
          <TextField id='search-input' label='약국명 입력' onKeyDown={onKeyPress} />
          <Button variant='contained' color='primary' onClick={onClickSearch}>
            검색
          </Button>
        </section>

        <section className='select__box'>
          <FormControl>
            <InputLabel>줄 수</InputLabel>
            <Select value={pageInfo.count} onChange={onChangeRowCount}>
              <MenuItem value={10}>10개</MenuItem>
              <MenuItem value={30}>30개</MenuItem>
              <MenuItem value={50}>50개</MenuItem>
            </Select>
          </FormControl>
        </section>
      </section>
      <section className='table__container'>
        <Table aria-label='collapsible table' component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>순번</TableCell>
              <TableCell align='center'>스토어 ID</TableCell>
              <TableCell align='center'>약국명</TableCell>
              <TableCell align='center'>면허번호</TableCell>
              <TableCell align='center'>에이전트 버전</TableCell>
              <TableCell align='center'>네트워크 에이전트 버전</TableCell>
              <TableCell align='center'>팜 프로그램</TableCell>
              <TableCell align='center'>약국IP</TableCell>
              <TableCell align='center'>에이전트 상태</TableCell>
              <TableCell align='center'>에이전트 모듈 연결</TableCell>
              <TableCell align='center'>네트워크 에이전트 상태</TableCell>
              <TableCell align='center'>태블릿 리스트</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={'table-row-' + row.name + '-' + row.storeId} row={row} />
            ))}
          </TableBody>
        </Table>
      </section>
      <section className='pagination__container'>
        <Pagination
          page={pageInfo.page}
          onChange={onChangePage}
          count={totalPage}
          color='primary'
          showFirstButton
          showLastButton
        />
      </section>
    </article>
  );
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    // cursor: 'pointer',
    // '&:hover': {
    //   background: '#efefef6b',
    // },
  },
});

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  let history = useHistory();

  const onClickTableRow = (e) => {
    // history.push("/store/" + row.storeId);
  };

  const onToggleExpand = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} onClick={onClickTableRow}>
        <TableCell align='center'>{row.index}</TableCell>
        <TableCell align='center'>{row.storeId}</TableCell>
        <TableCell align='center'>{row.name}</TableCell>
        <TableCell align='center'>{row.license}</TableCell>
        <TableCell align='center'>{row.agentVersion}</TableCell>
        <TableCell align='center'>{row.networkAgentVersion}</TableCell>
        <TableCell align='center'>{row.pharmProgram}</TableCell>
        <TableCell align='center'>{row.requestIp}</TableCell>
        <TableCell align='center'>{row.agentIsOn ? 'on' : 'off'}</TableCell>
        <TableCell align='center'>{row.agentModuleIsConn ? 'on' : 'off'}</TableCell>
        <TableCell align='center'>{row.networkAgentAlive}</TableCell>
        <TableCell align='center'>
          <IconButton aria-label='expand row' size='small' onClick={onToggleExpand}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box>
              <Typography variant='h6' gutterBottom component='div'>
                태블릿 목록
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>태블릿ID</TableCell>
                    <TableCell align='center'>디바이스ID</TableCell>
                    <TableCell align='center'>IP 주소</TableCell>
                    <TableCell align='center'>앱 버전</TableCell>
                    <TableCell align='center'>빌드 버전</TableCell>
                    <TableCell align='center'>코드 푸쉬 버전</TableCell>
                    <TableCell align='center'>구동 상태</TableCell>
                    <TableCell align='center'>구동 시간</TableCell>
                    <TableCell align='center'>연결 상태</TableCell>
                    <TableCell align='center'>연결 시간</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.tabletList.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10} style={{ height: '40px', textAlign: 'center' }}>
                        연결된 태블릿 정보가 없습니다.
                      </TableCell>
                    </TableRow>
                  ) : (
                    row.tabletList.map((tabletListRow) => (
                      <TableRow key={tabletListRow.tabletId} style={{ height: '60px' }}>
                        <TableCell align='center' component='th' scope='row'>
                          {tabletListRow.tabletId}
                        </TableCell>
                        <TableCell align='center' component='th' scope='row'>
                          {tabletListRow.deviceId}
                        </TableCell>
                        <TableCell align='center'>{tabletListRow.ipAddr}</TableCell>
                        <TableCell align='center'>{tabletListRow.appVersion}</TableCell>
                        <TableCell align='center'>{tabletListRow.buildVersion}</TableCell>
                        <TableCell align='center'>{tabletListRow.codePushVersion}</TableCell>
                        <TableCell align='center'>{tabletListRow.isOn ? 'on' : 'off'}</TableCell>
                        <TableCell align='center'>{tabletListRow.isOnDate}</TableCell>
                        <TableCell align='center'>
                          {tabletListRow.isConnected ? 'on' : 'off'}
                        </TableCell>
                        <TableCell align='center'>{tabletListRow.isConnectedDate}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
