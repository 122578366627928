import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Alert, Loading } from './components';
import {
  Login,
  Dashboard,
  Blog,
  PharmWait,
  PharmMonitor,
  PharmList,
  PharmDetail,
  PartnerList,
  PartnerRegister,
  PartnerDetail,
} from './pages';
import { MainLayout, LoginLayout } from './layout';
import { AlertContext, LoadingContext } from './context';

const App = () => {
  const initialAlert = {
    open: false,
    type: 'error',
    message: 'test message',
  };
  const [alert, updateAlert] = useState(initialAlert);

  const requestAlert = (value) => {
    console.log('App request Alert', value);
    updateAlert(value);
  };

  const initialLoading = {
    open: false,
  };
  const [loading, updateLoading] = useState(initialLoading);
  const requestLoading = (value) => {
    updateLoading(value);
  };

  return (
    <div className='App'>
      <AlertContext.Provider value={{ alert: alert, requestAlert: requestAlert }}>
        <LoadingContext.Provider value={{ loading: loading, requestLoading: requestLoading }}>
          <Alert data={alert} updateAlert={updateAlert} />
          <Loading data={loading} />
          <Router>
            <Switch>
              <MainLayout exact path='/' Component={Dashboard} headerText='대쉬보드'></MainLayout>
              <MainLayout
                exact
                path='/pharm/monitor'
                Component={PharmMonitor}
                headerText='약국 - 모니터링'></MainLayout>
              <MainLayout
                exact
                path='/pharm/list'
                Component={PharmList}
                headerText='약국 - 조회'></MainLayout>
              <MainLayout
                exact
                path='/pharm/detail/:storeId'
                Component={PharmDetail}
                headerText='약국 - 상세'></MainLayout>

              <MainLayout
                exact
                path='/pharm/wait'
                Component={PharmWait}
                headerText='약국 - 가입 대기 목록'></MainLayout>
              <MainLayout
                exact
                path='/partner/list'
                Component={PartnerList}
                headerText='파트너 - 조회'></MainLayout>
              <MainLayout
                exact
                path='/partner/register'
                Component={PartnerRegister}
                headerText='파트너 - 등록'></MainLayout>
              <MainLayout
                path='/partner/detail/:partnerId'
                Component={PartnerDetail}
                headerText='파트너 - 상세'></MainLayout>
              <LoginLayout exact path='/login' component={Login} />
            </Switch>
          </Router>
        </LoadingContext.Provider>
      </AlertContext.Provider>
    </div>
  );
};

export default App;
