import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const BASE_COLOR = '#1976d2';
const SECOND_COLOR = '#868686';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: BASE_COLOR,
    },
    secondary: {
      main: SECOND_COLOR,
    },
  },
  overrides: {
    MuiButton: {
      // Refer https://material-ui.com/api/button/
      containedPrimary: {
        color: '#fff',
      },
    },
  },
});

ReactDOM.render(
  <>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
