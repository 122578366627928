import AxiosInstance from '../axiosInstance';
import {
  ObjectToParams,
  ObjectToQueryString,
  ArrayToStringComma,
  statusStringToValue,
  arrayTOStringCommaByKey,
  dateFormatToStringType,
} from '../helper';

const getList = (obj) => {
  const queryString = ObjectToQueryString(obj);
  console.log('queryString', queryString);
  return AxiosInstance.get('pharms?' + queryString);
};

const getWaitList = () => {
  return AxiosInstance.get('pharms/subscriptions');
};

const getMonitoring = (obj) => {
  const queryString = ObjectToQueryString(obj);
  console.log('queryString', queryString);
  return AxiosInstance.get('pharms/monitoring?' + queryString);
};

const waitedPharmToPartner = (obj) => {
  console.log('obj', obj);
  let body = {
    partnerUid: obj.partnerUid,
  };
  console.log('body', body);
  return AxiosInstance.put(`pharms/subscriptions/${obj.subscriptionIdx}`, body);
};

const getDetail = (storeId) => {
  return AxiosInstance.get('pharms/' + storeId);
};

const Pharm = {
  getList: getList,
  getWaitList: getWaitList,
  getMonitoring: getMonitoring,
  waitedPharmToPartner: waitedPharmToPartner,
  getDetail: getDetail,
};

export default Pharm;
