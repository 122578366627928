import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';

const Inputs = (props) => {
  console.log('inputs props', props);
  const { data, onChange } = props;

  const useStyles = makeStyles((theme) => ({
    root: {},
  }));

  const classes = useStyles();

  const handleChange = (e, item, index) => {
    e.persist();
    console.log('handleChange', e.target);
    console.log('handleChange', item);
    console.log('handleChange', index);

    let newItem = _.clone(item);
    let newInputData = _.clone(data);

    // basic validation
    if (item.regExp) {
      console.log('item', item.regExp);
      let regExpResult = item.regExp.test(e.target.value);
      console.log('regExpResult', regExpResult);
      if (regExpResult) {
        newInputData[index].error = false;
        newInputData[index].helperText = null;
      } else {
        newInputData[index].error = true;
        newInputData[index].helperText = item.validationText;
      }
    }

    // password validation
    newInputData[index].value = e.target.value;
    const passwordItem = _.find(newInputData, (input) => {
      return input.id === 'password';
    });
    const passwordCheckItem = _.find(newInputData, (input) => {
      return input.id === 'password_check';
    });
    const passwordCheckItemIndex = _.findIndex(newInputData, (input) => {
      return input.id === 'password_check';
    });

    if (
      passwordCheckItem &&
      passwordCheckItem.value &&
      (item.id === 'password_check' || item.id === 'password')
    ) {
      if (passwordItem.value !== passwordCheckItem.value) {
        newInputData[passwordCheckItemIndex].error = true;
        newInputData[passwordCheckItemIndex].helperText = '비밀번호가 일치하지 않습니다.';
      } else {
        newInputData[passwordCheckItemIndex].error = false;
        newInputData[passwordCheckItemIndex].helperText = null;
      }
    }

    onChange(newInputData);
  };
  console.log('input render data', data);

  return (
    <>
      {data.map((inputItem, index) => {
        if (inputItem.type === 'select') {
          return (
            <TextField
              key={'form-input-' + inputItem.id + '-' + index}
              className={classes.root}
              id={inputItem.id}
              type={inputItem.type}
              select
              label={inputItem.label}
              variant='outlined'
              value={inputItem.value}
              inputProps={{
                readOnly: inputItem.readOnly,
              }}
              onChange={(e) => handleChange(e, inputItem, index)}>
              {inputItem.options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </TextField>
          );
        }
        return (
          <TextField
            key={'form-input-' + inputItem.id + '-' + index}
            className={classes.text_field_root}
            id={inputItem.id}
            type={inputItem.type}
            label={inputItem.label}
            variant='outlined'
            inputProps={{
              readOnly: inputItem.readOnly,
            }}
            onChange={(e) => handleChange(e, inputItem, index)}
            helperText={inputItem.helperText}
            error={inputItem.error}
            value={inputItem.value}
          />
        );
      })}
    </>
  );
};

export default Inputs;
