import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const Navigation = (props) => {
  const history = useHistory();

  const tabList = [
    {
      id: 'total',
      label: '통합',
      subList: [
        {
          id: 'main',
          label: '대쉬보드',
          to: '/',
        },
      ],
    },
    {
      id: 'pharm',
      label: '굿팜 - 약국',
      subList: [
        {
          id: 'pharm_monitor',
          label: '모니터링',
          to: '/pharm/monitor',
        },
        {
          id: 'pharm_list',
          label: '조회',
          to: '/pharm/list',
        },
        {
          id: 'pharm_register',
          label: '가입 대기 목록',
          to: '/pharm/wait',
        },
      ],
    },
    {
      id: 'partner',
      label: '굿팜 - 파트너',
      subList: [
        {
          id: 'partner_register',
          label: '등록',
          to: '/partner/register',
        },
        {
          id: 'partner_list',
          label: '조회',
          to: '/partner/list',
        },
      ],
    },
    // {
    //   id: "app",
    //   label: "goodpharm-app",
    //   subList: [
    //     {
    //       id: "store",
    //       label: "스토어",
    //       to: "/store",
    //     },
    //   ],
    // },
  ];

  const initialOpen = [];
  for (let i = 0; i < tabList.length; i++) {
    initialOpen.push(true);
  }

  const [open, updateOpen] = useState(initialOpen);

  const onClickList = (index) => {
    let newOpen = _.clone(open);
    if (newOpen[index]) {
      newOpen[index] = false;
    } else {
      newOpen[index] = true;
    }
    updateOpen(newOpen);
  };

  const useStyles = makeStyles(() => ({
    mainNavItem: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 'bold',
    },
    subNavItem: {
      color: 'rgba(0, 0, 0, 0.87)',
      paddingLeft: '20px',
    },
  }));
  const classes = useStyles();

  return (
    <>
      <article className='main-navigation-header'>
        <div>
          <Link to='/'>HealthPort - Admin</Link>
        </div>
      </article>
      <List component='nav' className='main-navigation' disablePadding={true}>
        {tabList.map((navItem, index) => {
          return (
            <>
              <ListItem button onClick={() => onClickList(index)}>
                <ListItemText
                  classes={{
                    primary: classes.mainNavItem,
                  }}
                  primary={navItem.label}
                />
              </ListItem>
              <Collapse in={open[index]} timeout='auto' unmountOnExit>
                <List component='div' disablePadding className='nested-navigation'>
                  {navItem.subList.map((subItem, subIndex) => {
                    return (
                      <Link to={subItem.to}>
                        <ListItem
                          button
                          className={history.location.pathname === subItem.to ? 'active' : ''}>
                          <ListItemText
                            classes={{
                              primary: classes.subNavItem,
                            }}
                            primary={subItem.label}
                          />
                        </ListItem>
                      </Link>
                    );
                  })}
                </List>
              </Collapse>
            </>
          );
        })}
      </List>
    </>
  );
};

export default Navigation;
