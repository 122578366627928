export const ObjectToParams = (data) => {
  const params = new URLSearchParams();
  Object.keys(data).forEach((key) => {
    params.append(key, data[key]);
  });
  return params;
};

export const ObjectToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const ArrayToStringComma = (array) => {
  const string = array.join();
  return string;
};

export const statusStringToValue = (text) => {
  switch (text) {
    case '설치접수': {
      return 'R';
    }
    case '설치예정': {
      return 'S';
    }
    case '설치보류': {
      return 'H';
    }
    case '설치완료': {
      return 'F';
    }
    case '설치취소': {
      return 'C';
    }
    default: {
      return '';
    }
  }
};

export const arrayTOStringCommaByKey = (array, key) => {
  let targetArray = [];
  for (let i in array) {
    targetArray.push(array[i][key]);
  }
  const string = targetArray.join();
  return string;
};

export const dateFormatToStringType = (dateValue) => {
  const yyyy = dateValue.getFullYear();
  let mm = dateValue.getMonth() + 1;
  if (mm < 10) {
    mm = '0' + mm;
  }
  let dd = dateValue.getDate();
  if (dd < 10) {
    dd = '0' + dd;
  }
  return `${yyyy}-${mm}-${dd}`;
};
